export default {
  computed: {
    mixinNetVersionUpFlg() {
      return this.$store.getters['petorelu/get'].netVersionUpFlg
    }
  },
  watch: {
    mixinNetVersionUpFlg: function(mixinNetVersionUpFlg) {
      if (mixinNetVersionUpFlg) {
        window.removeEventListener('beforeunload', this.handler)
      }
    }
  },
  created() {
    window.addEventListener('beforeunload', this.handler)
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.handler)
  },
  methods: {
    handler(event) {
      event.returnValue = "Data you've inputted won't be synced"
    }
  }
}
